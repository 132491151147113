import axios from 'axios'
import {toast} from 'react-toastify'
import _ from 'lodash'

const instance = axios.create(
    {
        baseURL: 'http://172.17.39.30/api',
        headers: {'Content-Type': 'application/json'}
    }
);
// baseURL: 'http://172.17.39.30/api',
// baseURL: 'http://moderation-tool.test/api',

instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    let response = error.response;
    let message = _.get(response, 'data.message', 'Internal Server Error');
    toast.error(message);
    if (response.status === 401) {
        client30.logout();
        window.location.reload();
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export async function client30(endpoint, body) {
    const config = {
        url: endpoint,
        method: 'post',
        data: body
    };
    try {
        const response = await instance(config);
        return response.data
    } catch (err) {
        return Promise.reject(_.get(err.response, 'data', 'Internal Server Error'))
    }
}

client30.setAuthorizationToken = function (token) {
    if (token) {
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete instance.defaults.headers.common['Authorization']
    }
};

client30.logout = function () {
    localStorage.removeItem('auth');
    client30.setAuthorizationToken()
};

client30.post = function (endpoint, body) {
    return client30(endpoint, body)
};