import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'

// We'll use redux-logger just as an example of adding another middleware
import logger from 'redux-logger';

// And use redux-batch as an example of adding enhancers
import {reduxBatch} from '@manaflair/redux-batch'

import authenticationReducer from '../features/authentication/authenticationSlice'
import projectsReducer from '../features/projects/projectsSlice'
import mediumsReducer from '../features/mediums/mediumsSlice'

const reducer = {
    authentication: authenticationReducer,
    projects: projectsReducer,
    mediums: mediumsReducer,
};

let enable = false
let middleware = [...getDefaultMiddleware()];
if (enable) {
    middleware = [...getDefaultMiddleware(), logger];
}


export default configureStore({
    reducer: reducer,
    middleware,
    enhancers: [reduxBatch]
});