import React from "react";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import {Container} from "react-bootstrap";
import {PrivateRoute} from "./components";
import {LoginForm} from "./features/authentication/LoginForm";
import 'react-toastify/dist/ReactToastify.min.css'
import {toast} from 'react-toastify'
import {ProjectsList} from "./features/projects/ProjectsList";
import {SingleProjectPage} from "./features/projects/SingleProjectPage";
import {ReportPage} from "./features/mediums/ReportPage";
import {useHotkeys} from "react-hotkeys-hook";

toast.configure({
    autoClose: 5000
});

const TestComponent = () => {
    useHotkeys('backspace, del', (event, handler) => {
        alert(handler.key)
    },[]);
    return (
        <div>Hello</div>
    )
}

function App() {
    return (
        <React.Fragment>
            <BrowserRouter>
                <main role="main">
                    <Container fluid>
                        <Switch>
                            <PrivateRoute exact path="/" component={ProjectsList}/>
                            <PrivateRoute exact path="/projects/:projectId" component={SingleProjectPage}/>
                            <Route path="/login" component={LoginForm}/>
                            <Route path="/report" component={ReportPage}/>
                            <Route path="/test" component={TestComponent}/>
                            <Redirect from="*" to="/" />
                        </Switch>
                    </Container>
                </main>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App