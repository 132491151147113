import React, {useEffect, useState} from "react";
import {Form, Button, Row, Col} from 'react-bootstrap'
import Spinner from "react-bootstrap/Spinner";
import {useDispatch, useSelector} from "react-redux";
import {login, logout} from "./authenticationSlice";
import {Redirect} from "react-router-dom";

export const LoginForm = () => {
    const dispatch = useDispatch();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isRedirect, setRedirect] = useState(false);

    // reset login status. Call only once
    useEffect(() => {
        dispatch(logout());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onUserNameChanged = e => setUserName(e.target.value);
    const onPasswordChanged = e => setPassword(e.target.value);
    const status = useSelector(state => state.authentication.status);

    const canSubmit = [userName, password].every(Boolean) && status === 'idle';

    let buttonContent;
    if (status !== 'idle') {
        buttonContent = <Spinner animation="border"/>
    } else {
        buttonContent = <span>Sign In</span>
    }
    if (isRedirect) {
        return <Redirect to="/"/>
    }

    const handleSubmit = async () => {
        if (canSubmit) {
            try {
                await dispatch(
                    login({
                        user_name: userName,
                        password: password
                    })
                );
                setRedirect(true)
            } catch (e) {
                console.error('Failed to login: ', e)
            }
        }
    };


    return (
        <Row className="justify-content-center mt-5">
            <Col xs={3}>
                <Form>
                    <Form.Group>
                        <Form.Label className="text-white">User name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter user name"
                            value={userName}
                            onChange={onUserNameChanged}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label className="text-white">Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={onPasswordChanged}
                        />
                    </Form.Group>
                    <Button variant="primary" type="button" block disabled={!canSubmit} onClick={handleSubmit}>
                        {buttonContent}
                    </Button>
                </Form>
            </Col>
        </Row>
    )
};