import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchProjects, selectProjectById, selectProjectIds} from "./projectsSlice";
import styles from './Project.module.scss'
import {Link} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import ContentLoader from "react-content-loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faScroll} from "@fortawesome/free-solid-svg-icons";

const LoadingListProject = () => {
    return (
        <ContentLoader
            speed={2}
            width={700}
            height={150}
            viewBox="0 0 700 150"
            backgroundColor="#ffffff"
            foregroundColor="#0f0f0f"
        >
            <circle cx="10" cy="20" r="8" />
            <rect x="25" y="15" rx="5" ry="5" width="600" height="10" />
        </ContentLoader>
    )
};

let ProjectExcerpt = ({projectId}) => {
    const project = useSelector((state) => selectProjectById(state, projectId));
    return (
        <li className="border rounded mb-2">
            <Link className="p-3 d-block" to={`/projects/${project.id}`}><FontAwesomeIcon icon={faScroll}/> {project.name}</Link>
        </li>
    )
};

export function ProjectsList() {
    const dispatch = useDispatch();
    const orderedProjectIds = useSelector(selectProjectIds);

    const projectStatus = useSelector((state) => state.projects.status);
    useEffect(() => {
        if (projectStatus === 'idle') {
            dispatch(fetchProjects())
        }
    }, [projectStatus, dispatch]);

    let content = <LoadingListProject/>;
    if (projectStatus === 'loading') {
        content = <LoadingListProject/>
    } else if (projectStatus === 'succeeded') {
        content = orderedProjectIds.map((projectId) => (
            <ProjectExcerpt key={projectId}  projectId={projectId}/>
        ))
    } else {
        content = null
    }
    return (
        <section className={styles['projects-list']}>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={8}>
                        <h2>Projects</h2>
                        <ul className="pl-0">
                            {content}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}