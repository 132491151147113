export const projectsConstants = {
    LOADING: 'loading',
    SUCCEEDED: 'succeeded',
    IDLE: 'idle',
    ERROR: 'error',
    RACECAR: 'RaceCar',
    STORYTELLING_QUEUE2: 'Storytelling_Queue2',
    VID_STREAMING: 'Vid Streaming',
    KIDS: 'Kids',
    IMAGE_SHARING_2: 'Image Sharing 2',
    PROJECT_ID_VID_STREAMING: 4,
    IS1_ALBUM: 'IS1_Album',
    IS1_SINGLE: 'IS1_Single',
    LOVE: 'Love',
    LOVE_AUDIO: 'Love_Audio',
};