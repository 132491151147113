import * as moment from "moment";
import _ from 'lodash'

const LOCAL_STORAGE_KEY = 'alert_logs'

class AlertLog {
    constructor(userId) {
        this.userId = userId
    }

    getAlertTimes() {
        try {
            const now = moment().format('YYYYMMDD')
            let info = localStorage.getItem(LOCAL_STORAGE_KEY)
            if (!info) {
                return 0;
            }
            info = JSON.parse(info)
            if (!info.hasOwnProperty(now)) {
                localStorage.removeItem(LOCAL_STORAGE_KEY)
            }
            let alertTimes = _.get(info, [now, this.userId], 0)
            return parseInt(alertTimes)
        } catch (err) {
            return 0
        }
    }

    setAlertTimes() {
        const now = moment().format('YYYYMMDD')
        let info = localStorage.getItem(LOCAL_STORAGE_KEY)
        if (info) {
            info = JSON.parse(info)
        } else {
            info = {}
        }
        let alertTimes = _.get(info, [now, this.userId], 0)
        _.setWith(info, [now, this.userId], alertTimes + 1, Object)
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(info))
    }
}

export default AlertLog