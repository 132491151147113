import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchProject, selectProjectById } from "./projectsSlice";
import { Button, Col, Image, Modal, Row, Spinner } from "react-bootstrap";
import { ProjectNotFound } from "./ProjectNotFoundPage";
import {
    fetchMediums,
    fetchSideInfo,
    moderateMediums, setIdle,
    setPreviousMediums,
    setQueueMedia, setStatusProcessing, setStatusSucceeded, setUserActiveIdle, setUserIdle, setGlobalIdleAlert, setGlobalIdleAlert10, setGlobalIdleAlert30, setGlobalIdleAlert31
} from "../mediums/mediumsSlice";
import { idleConstants, mediumsConstants } from "../../constants";
import { SingleMediaBox } from "../mediums/SingleMediaBox";
import { LoadingMediums } from "../mediums/LoadingMediums";
import styles from './Project.module.scss'
import { useHotkeys } from "react-hotkeys-hook";
import { unwrapResult } from "@reduxjs/toolkit";
import _ from 'lodash'
import * as moment from "moment";
import ReactPlayer from "react-player";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { faSearchPlus, faSearchMinus, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Countdown from "react-countdown";
import { useIdleTimer } from 'react-idle-timer'
import AlertLog from '../../app/alert_logs'
import { projectsConstants } from "../../constants";

const MySwal = withReactContent(Swal)


const LoadingProject = () => {
    return (
        <Row className="justify-content-center">
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </Row>
    )
};

const MainContent = ({ queueMedia, project, handleFieldChange, openModal, is1AlbumCurrentIndexToShow }) => {

    const hotkeys = ['q', 'w', 'e'];
    const mediumStatus = useSelector((state) => state.mediums.status);
    const moderatingTimeout = parseInt(project['moderating_timeout'])
    const [timeCountDown] = useState(Date.now() + moderatingTimeout * 1000)
    const dispatch = useDispatch();
    const onCountDownComplete = () => {
        if (!queueMedia.length && mediumStatus !== mediumsConstants.IDLE) {
            dispatch(setIdle())
        }
    }

    const triggerFetching = () => {
        dispatch(setIdle())
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return null
        } else {
            return <p className="d-block small font-italic">
                Auto <u onClick={triggerFetching} className={styles['cursor-pointer']}>fetching</u> in {seconds} seconds
            </p>
        }
    };
    let content = <div className="text-center col">
        <h1>
            The media queue is currently empty!
            <Countdown
                date={timeCountDown}
                key={timeCountDown.toString()}
                renderer={renderer}
                onComplete={onCountDownComplete}
            />
        </h1>
    </div>;
    if (queueMedia.length) {
        // if (project.name == "Kids" && queueMedia.length > 2){
        //     queueMedia = queueMedia.slice(0, 2)
        // }
        if (project.name === projectsConstants.IS1_ALBUM){
            queueMedia = queueMedia.slice().sort((a, b) => 
                a['note'].split(" | ")[0] === "album_hash" || 
                b['note'].split(" | ")[0] === "album_hash" || 
                parseInt(a['note'].split(" | ")[2]) > parseInt(b['note'].split(" | ")[2]) ? 1 : -1);   
        }
        content = queueMedia.map((media, index) => (
            <SingleMediaBox handleFieldChange={handleFieldChange} hotkeys={hotkeys} media={media} key={media.id}
                project={project} keyboard={hotkeys[project.name === projectsConstants.IS1_ALBUM ? index-1 : index]} position={index} openModal={openModal} is1AlbumCurrentIndexToShow={is1AlbumCurrentIndexToShow} />
        ));
    }

    return (
        <Row id={styles['main-content']}>
            {content}
        </Row>
    )
};

const ImageContainer = ({ url }) => {
    return (
        <TransformWrapper
            defaultScale={1}
            defaultPositionX={200}
            defaultPositionY={100}
        >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" className="mr-2" onClick={zoomIn}><FontAwesomeIcon
                            icon={faSearchPlus} /></Button>
                        <Button variant="info" className="mr-2" onClick={zoomOut}><FontAwesomeIcon
                            icon={faSearchMinus} /></Button>
                        <Button variant="warning" className="mr-2" onClick={resetTransform}><FontAwesomeIcon
                            icon={faExpandArrowsAlt} /></Button>
                    </div>
                    <TransformComponent>
                        <Image src={url} className="img-fluid d-block m-auto" alt="" />
                    </TransformComponent>
                </React.Fragment>
            )}
        </TransformWrapper>
    )
}

const ModalMedia = (props) => {
    const media = props.media;
    const project = props.project;

    let content;
    if (media) {
        const server = process.env.REACT_APP_BASE_URL;
        const mediaType = media['media_type'];
        const mediaUrl = `${server}storage/media/${project.id}/${media['media_path']}`;
        if (mediaType === mediumsConstants.IMAGE || mediaType === mediumsConstants.GIF) {
            content = <ImageContainer url={mediaUrl} />
        } else if (mediaType === mediumsConstants.VIDEO) {
            content = <ReactPlayer url={mediaUrl} playing width="100%" height="100%" loop muted controls />
        }
    }

    return (
        <Modal
            size="lg"
            centered
            {...props}
            dialogClassName="media-zoom-modal"
        >
            <Modal.Body className="p-0">
                {content}
            </Modal.Body>
        </Modal>
    )
};

const ModalAlertWindow = (props) => {
    return (
        <Modal
            size="lg"
            centered
            {...props}
            dialogClassName="media-zoom-modal"
        >
            <Modal.Body className={styles['alert-modal']} >
                {props.content}
            </Modal.Body>
        </Modal>
    )
};
const IdleAlertMessage = (projectName, alertTime) => {
    return (
        <div>
            <b>{projectName}</b> đang có ảnh trên tool cần làm ngay. Đây là cảnh báo lần {alertTime}
        </div>
    )
};
const GlobalAlertMessage = (projectName) => {
    return (
        <div>
            Bạn nhận được thông báo này do dự án <b>{projectName}</b> đang xảy sự cố. Hãy giúp tôi:
            <ul>
                <li>Nhắc người làm dự án <b>{projectName}</b> làm việc tập trung hơn để không thông báo này không hiện lên nữa.</li>
                <li>Gọi điện ngay cho Management Team nếu có bất cứ vấn đề gì phát sinh.</li>
            </ul>
            Thanks.
        </div>
    )
};

const MediaInQueueLongerThanExpectedAlertMessage = (projectName) => {
    return (
        <div>
            Bạn nhận được thông báo này do dự án <b>{projectName}</b> đang không có ai làm việc hoặc có sự cố phát sinh. Hãy giúp tôi:
            <ul>
                <li>Nhắc người làm dự án <b>{projectName}</b> đăng nhập lại vào tool và làm việc tập trung hơn để thông báo này không hiện lên nữa.</li>
                <li>Gọi điện ngay cho Management Team nếu có bất cứ vấn đề gì phát sinh.</li>
            </ul>
            Thanks.
        </div>
    )
};

const LastSubmittedThan15ProjectNameAlertMessage = (projectName) => {
    return (
        <div>
            <b>{projectName}</b> trong vòng 15 phút qua chưa có ảnh nào được submit đi.
            <ul>
                <li>Hãy kiểm tra lại Tool, Queue, kết nối internet.</li>
                <li>Gọi điện ngay cho Management Team nếu có bất cứ vấn đề gì phát sinh.</li>
            </ul>
            Thanks.
        </div>
    )
};

const LastSubmittedThan30ProjectNameAlertMessage = (projectName) => {
    return (
        <div>
            <b>{projectName}</b> trong vòng 30 phút qua chưa có ảnh nào được submit đi.
            <br/>
            Đây là cảnh báo lần 2
        </div>
    )
};

const ProjectContent = ({ project }) => {
    let debug = false
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const mediumStatus = useSelector((state) => state.mediums.status);
    const queueMedia = useSelector((state) => state.mediums.queueMedia);
    const moderateStatus = useSelector((state) => state.mediums.moderateStatus);
    const errorFetchMediumsMsg = useSelector((state) => state.mediums.errorMsg);
    const previousMedias = useSelector((state) => state.mediums.previousMedias);
    const sideInfo = useSelector((state) => state.mediums.sideInfo);
    const [values, setValues] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [modalShow, setShowModal] = useState(false);
    const [media, setMedia] = useState(null);
    const [modalAlertShow, setShowModalAlert] = useState(false);
    const [modalAlertMessage, setModalAlertMessage] = useState(null);
    const [backPos, setBackPos] = useState(null)
    const [modifiedMedia, setModifiedMedia] = useState(null)
    const [timeCountDown, setTimeCountDown] = useState(Date.now() + 10000)
    const idleStatus = useSelector((state) => state.mediums.idleState);
    const globalAlert = useSelector((state) => state.mediums.globalAlert);
    const globalAlertProject = useSelector((state) => state.mediums.globalAlertProject);
    const mediaInQueueAlert = useSelector((state) => state.mediums.mediaInQueueAlert);
    const mediaInQueueAlertProjectName = useSelector((state) => state.mediums.mediaInQueueAlertProjectName);
    const lastSubmittedThan15Alert = useSelector((state) => state.mediums.lastSubmittedThan15Alert);
    const lastSubmittedThan15ProjectName = useSelector((state) => state.mediums.lastSubmittedThan15ProjectName);
    const lastSubmittedThan30Alert = useSelector((state) => state.mediums.lastSubmittedThan30Alert);
    const lastSubmittedThan30ProjectName = useSelector((state) => state.mediums.lastSubmittedThan30ProjectName);
    const alertLogs = new AlertLog(user.id);
    const [alertTime, setAlertTime] = useState(alertLogs.getAlertTimes());
    const isAdmin = user['is_admin'];
    const [submitButtonStyle, setSubmitButtonStyle] = useState('secondary');
    const [is1AlbumCurrentIndexToShow, setIs1AlbumCurrentIndexToShow] = useState(3);
    let mainContent = <LoadingMediums />;
    const canSubmit = moderateStatus === mediumsConstants.IDLE && Object.keys(values).length && !isSubmit && !isAdmin;
    const hasInteractiveMedia = queueMedia.find(el => {
        let mediaType = el['media_type']
        return mediaType === mediumsConstants.VIDEO || mediaType === mediumsConstants.GIF
    })
    const defaultIdleTimeout = 30000
    let idleTime = defaultIdleTimeout

    if (project.idle_time && parseInt(project.idle_time) * 1000 > defaultIdleTimeout) {
        idleTime = parseInt(project.idle_time) * 1000
    }

    // Refresh won't get project.idle_time from the server
    if (project.name === "Kids" || project.name === "ImageSharing2_Video") {
        idleTime = 181 * 1000;
        // idleTime = 5000;
    } else if (project.name === "WeLoveIt_Low") {
        idleTime = 43200 * 1000;
    } else if (project.name === "WeLoveIt_High" || project.name === "IS1_Single" || project.name === projectsConstants.RACECAR || project.name === projectsConstants.STORYTELLING_QUEUE2) {
        idleTime = 300 * 1000;
    } else if (project.name === "Battle 1.0" || project.name === "Vid Streaming") {
        idleTime = 120 * 1000;
    }

    let defaultRemainingBeforeSubmit = 0;

    if (project.name === projectsConstants.KIDS) {
        defaultRemainingBeforeSubmit = 10;
    } else if (project.name === projectsConstants.VID_STREAMING || project.name === projectsConstants.IMAGE_SHARING_2) {
        defaultRemainingBeforeSubmit = 3;
    } 

    if (isAdmin || (user != null && user.user_name.toLowerCase() === 'luanvu')) {
        debug = true;
    }
    if (debug) {
        idleTime = 43200 * 1000;
    }

    const [remainingBeforeSubmit, setRemainingBeforeSubmit] = useState(defaultRemainingBeforeSubmit)
    const [remaining, setRemaining] = useState(idleTime)
    const [idleClassName, setIdleClassName] = useState(null)

    useEffect(() => {
        if (isAdmin) {
            return
        }
        if (mediumStatus === mediumsConstants.IDLE) {
            dispatch(fetchMediums({
                'project_id': project.id
            }));
        }
        var hostname = window.location.hostname;
        if (hostname === "172.17.39.30") {
            hostname = "vnpt3";
        } else if (hostname === "172.17.31.31") {
            hostname = "vnpt5";
        }
        document.title = project.name + " - " + hostname;

    }, [mediumStatus, project, dispatch, isAdmin]);

    useEffect(() => {
        if (globalAlert) {
            setShowModalAlert(true);
            setModalAlertMessage(GlobalAlertMessage(globalAlertProject));
        }

    }, [globalAlert, queueMedia, globalAlertProject]);

    useEffect(() => {
        if (mediaInQueueAlert) {
            setShowModalAlert(true);
            setModalAlertMessage(MediaInQueueLongerThanExpectedAlertMessage(mediaInQueueAlertProjectName));
        }

    }, [mediaInQueueAlert, queueMedia, mediaInQueueAlertProjectName]);
    
    useEffect(() => {
        if (lastSubmittedThan15Alert) {
            setShowModalAlert(true);
            setModalAlertMessage(LastSubmittedThan15ProjectNameAlertMessage(lastSubmittedThan15ProjectName));
        }

    }, [lastSubmittedThan15Alert, queueMedia, lastSubmittedThan15ProjectName]);
    
    // useEffect(() => {
    //     if (lastSubmittedThan30Alert) {
    //         setShowModalAlert(true);
    //         setModalAlertMessage(LastSubmittedThan30ProjectNameAlertMessage(lastSubmittedThan30ProjectName));
    //     }

    // }, [lastSubmittedThan30Alert, queueMedia, lastSubmittedThan30ProjectName]);

    const handleFieldChange = useCallback((fieldId, value) => {
        setValues(prev => {
            let newValues = { ...prev };
            newValues[fieldId] = value;
            return newValues
        });
    }, []);

    const openModal = (medal) => {
        if (!modalShow) {
            setShowModal(true);
            setMedia(medal);
        } else {
            setShowModal(false);
            setMedia(null);
        }
    };

    const handleOnIdle = async (event) => {
        if (isAdmin || (user != null && user.user_name.toLowerCase() === 'luanvu')) {
            return;
        }
        if (!queueMedia.length) {
            reset()
            return;
        }
        const mediaIds = queueMedia.map(el => el.media_id)
        let idleAlertTime = alertTime + 1;
        let data = {
            'media_ids': mediaIds,
            'project_name': project.name,
            'inactive_time': moment().format('YYYY-MM-DD HH:mm:ss Z'),
            'alert_time': idleAlertTime,
            'user_name': user.user_name
        };
        const responseUserIdle = await dispatch(setUserIdle(data));
        console.log(responseUserIdle);

        //Request to insert global alert every 3 idle alert
        if (idleAlertTime % 3 === 0) {
            // const localhost = await dispatch(setGlobalIdleAlert(data));

            const responseGlobalIdleAlert10 = await dispatch(setGlobalIdleAlert10(data));
            console.log(responseGlobalIdleAlert10);
            const responseGlobalIdleAlert30 = await dispatch(setGlobalIdleAlert30(data));
            console.log(responseGlobalIdleAlert30);
            const responseGlobalIdleAlert31 = await dispatch(setGlobalIdleAlert31(data));
            console.log(responseGlobalIdleAlert31);
        }

        reset()
        setAlertTime(idleAlertTime)
        alertLogs.setAlertTimes()

        setShowModalAlert(true);
        setModalAlertMessage(IdleAlertMessage(project.name, (idleAlertTime)));

        let audio = new Audio("/warning-sound-6686.mp3")
        audio.play();
    }

    const { getRemainingTime, reset } = useIdleTimer({
        timeout: idleTime,
        onIdle: handleOnIdle,
        debounce: 500
    })

    useEffect(() => {
        const renderIdleRemainingTime = () => {
            let remainTime = getRemainingTime()
            let calcTime = Math.floor(remainTime / 1000)
            setRemaining((calcTime))
            if (calcTime >= 20) {
                setIdleClassName('text-primary')
            } else if (calcTime >= 10 && calcTime < 20) {
                setIdleClassName('text-warning')
            } else {
                setIdleClassName('text-danger')
            }
        }
        renderIdleRemainingTime()
        setInterval(() => {
            renderIdleRemainingTime()
        }, 1000)
    }, [])

    useEffect(() => {
        if (idleStatus === idleConstants.RESET) {
            reset()
            dispatch(setUserActiveIdle());
        }
    }, [idleStatus, reset, dispatch])

    useEffect(() => {
        const timer = setTimeout(function () {
            if (!queueMedia.length) {
                return;
            }
            if (remainingBeforeSubmit > 0) {
                setRemainingBeforeSubmit(remainingBeforeSubmit - 1);
            } 

            if (remainingBeforeSubmit > 1) {
                setSubmitButtonStyle('secondary');
            } else {
                setSubmitButtonStyle('primary');
            }
        }, 1000)

        return () => {
            clearTimeout(timer)
        }
    }, [remainingBeforeSubmit, queueMedia.length]);

    if (!isAdmin) {
        if (mediumStatus === mediumsConstants.SUCCEEDED) {
            mainContent = <MainContent handleFieldChange={handleFieldChange} queueMedia={queueMedia} project={project}
                openModal={openModal} is1AlbumCurrentIndexToShow={is1AlbumCurrentIndexToShow} />
        } else if (mediumStatus === mediumsConstants.ERROR) {
            mainContent = <h1 className="text-center text-danger">{errorFetchMediumsMsg}</h1>
        }
    } else {
        mainContent = null
    }


    useHotkeys('p', () => {
        window.location.reload()
    });
    
    const submitProcess = () => {
        if (project.name === projectsConstants.KIDS || project.name === projectsConstants.VID_STREAMING || project.name === projectsConstants.IMAGE_SHARING_2) {
            if (remainingBeforeSubmit > 0) {
                return;
            } 
        }
        if (project.name === projectsConstants.IS1_ALBUM) {           
            if (queueMedia.length > 3 && is1AlbumCurrentIndexToShow < queueMedia.length-1) {
                MySwal.fire({
                    title: 'Vui lòng xem hết ảnh.' 
                }).then((result) => {
                })
                return;    
            } 
        }
        if (canSubmit && !isSubmit) {
            if (modalShow) {
                setShowModal(false);
                return
            }
            if (hasInteractiveMedia) {
                MySwal.fire({
                    title: 'Bạn đã chắc chắn xem hết ảnh GIF chưa?',
                    showCancelButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        submitData().then(() => {
                        })
                    }
                })
            } else if (project.name === projectsConstants.KIDS) {
                MySwal.fire({
                    title: 'Bạn chắc chắn submit chứ?',
                    showCancelButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        submitData().then(() => {
                        })
                    }
                })
            } 
            else {
                submitData().then(() => {
                })
            }
        }
    }

    const submitData = async () => {
        setIsSubmit(true);
        const cloneMediums = _.cloneDeep(queueMedia);
        const handleFinally = () => {
            dispatch(setIdle());
            setIsSubmit(false)
            setValues({});
            setModifiedMedia(null);
            setBackPos(null);
            setIs1AlbumCurrentIndexToShow(3);

            setRemainingBeforeSubmit(defaultRemainingBeforeSubmit);
            if (defaultRemainingBeforeSubmit > 1) {
                setSubmitButtonStyle('secondary');
            } else {
                setSubmitButtonStyle('primary');
            }        
        }
        try {
            let data = {
                'mediums': values,
                'project_id': project.id,
                'isValidToSubmit': true,
            };
            if (modifiedMedia) {
                const backModifyTime = project['back_modify_time'];
                const currentTime = moment().unix();
                const modifiedTime = modifiedMedia.time;
                data.modifiedTime = modifiedTime;
                data.reSubmitted = currentTime;
                const diff = currentTime - modifiedTime;
                if (diff > backModifyTime) {
                    data.isValidToSubmit = false;
                    // return
                }
            }
            
            const result = await dispatch(moderateMediums(data));
            unwrapResult(result);
            dispatch(setPreviousMediums(cloneMediums));
        } catch (e) {
            console.error('Failed to Submit: ', e)
        } finally {
            handleFinally()
        }
    };

    useHotkeys('space', (event, handler) => {
        // prevent scroll when enter "space"
        event.preventDefault();
        submitProcess()
    }, [
        values, project, dispatch,
        setIsSubmit, isSubmit, queueMedia,
        setPreviousMediums, setValues,
        modalShow, setShowModal, backPos,
        setModifiedMedia, modifiedMedia,
        setIdle, setBackPos, hasInteractiveMedia, submitProcess
    ]);

    useHotkeys('Backspace, Del', (event, handler) => {
        event.preventDefault();
        setRemainingBeforeSubmit(0);

        let nextPos
        if (backPos === null) {
            nextPos = 0
        } else {
            nextPos = backPos + 1
        }
        if (previousMedias[nextPos]) {
            let modifiedMedia = previousMedias[nextPos]
            const mediums = modifiedMedia.mediums;
            setValues({});
            dispatch(setStatusProcessing());
            dispatch(setQueueMedia(mediums));
            setBackPos(nextPos);
            setModifiedMedia(modifiedMedia);
            dispatch(setStatusSucceeded());
        }
    }, [
        previousMedias, project, dispatch,
        setStatusProcessing, setStatusSucceeded, backPos,
        setBackPos, setModifiedMedia, setQueueMedia, setValues
    ]);

    const refresh = () => {
        window.location.reload()
    };

    const openAllRCQueues = () => {
        window.open('https://online-milenet-us3.milestone.it/services/v1/StorageModeration/5fd108e0652cd070e4158a5b/?secretKey=OHgvQT9EKEcrS2JQZVNnVmtZcDNzNnY5eSRCJkUpSEBNY1FmVGpXbVpxNHQ3dyF6JUMqRi1KYU5kUmdVa1hwMg');
        window.open('https://online-milenet-us3.milestone.it/services/v1/StorageModeration/61016c3e145dae0c61288691/?secretKey=OHgvQT9EKEcrS2JQZVNnVmtZcDNzNnY5eSRCJkUpSEBNY1FmVGpXbVpxNHQ3dyF6JUMqRi1KYU5kUmdVa1hwMg');
        window.open('https://online-milenet-us3.milestone.it/services/v1/StorageModeration/629df6b22bce325b8c908087/?secretKey=MnM1dTh4L0E_RChHK0tiUGVTaFZtWXEzdDZ3OXkkQiZFKUhATWNRZlRqV25acjR1N3ghQSVDKkYtSmFOZFJnVQ');
        window.open('https://online-milenet-us3.milestone.it/services/v1/StorageModeration/6408957d7032fb306a7278e1/?secretKey=MnM1dTh4L0E_RChHK0tiUGVTaFZtWXEzdDZ3OXkkQiZFKUhATWNRZlRqV25acjR1N3ghQSVDKkYtSmFOZFJnVQ');
    };

    // Renderer callback with condition
    const statRender = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // callFetch()
            return null
        } else {
            // Render a countdown
            return <span className="small font-italic">Auto update in {seconds} seconds</span>
        }
    };
    useEffect(() => {
        dispatch(fetchSideInfo({ 'project_id': project.id }))
    }, [project, dispatch]);

    const fetchProjectStatistics = () => {
        dispatch(fetchSideInfo({ 'project_id': project.id })).then(() => {
            setTimeCountDown(Date.now() + 5000)
        })
    }

    let idleRender;
    if (!isAdmin && remaining > 0) {
        idleRender = <small className={`small font-weight-bold font-italic ${idleClassName}`}>Idle Time
            Remaining: {remaining} seconds</small>
    }

    let alertRender;
    if (alertTime) {
        alertRender =
            <small className={`small font-weight-bold text-warning`}>Email Alert {alertTime} times today</small>
    }

    let timeBeforeSubmitRender;
    if (project.name === projectsConstants.KIDS || project.name === projectsConstants.VID_STREAMING || project.name === projectsConstants.IMAGE_SHARING_2) {
        timeBeforeSubmitRender = <h4 className={`font-weight-bold font-italic text-warning`}>
            {remainingBeforeSubmit} seconds</h4>
    }

    // useEffect(() => {
    //     const script = document.createElement('script');
      
    //     script.src = "/script.js";
    //     script.async = false;
      
    //     document.body.appendChild(script);
      
    //     return () => {
    //       document.body.removeChild(script);
    //     }
    //   }, []);

    useHotkeys('a', () => {
        SetIs1AlbumIndex("previous");
    }, [
        is1AlbumCurrentIndexToShow, queueMedia
    ]);
    useHotkeys('d', () => {
        SetIs1AlbumIndex("next");
    }, [
        is1AlbumCurrentIndexToShow, queueMedia
    ]);

    const SetIs1AlbumIndex = (previousNext) => {
        setIs1AlbumCurrentIndexToShow(prev => {
            if (previousNext === "next") {
                let queueLengthExceptAlbumHash = queueMedia.length - 1;
                if (prev + 3 > queueLengthExceptAlbumHash){
                    if (queueLengthExceptAlbumHash % 3 === 1) {
                        return queueLengthExceptAlbumHash + 2;
                    } else if (queueLengthExceptAlbumHash % 3 === 2) {
                        return queueLengthExceptAlbumHash + 1;
                    }
                    return queueLengthExceptAlbumHash;
                }
                return prev + 3;
            } else {
                if (prev - 3 <= 0){
                    return 3;
                }
                return prev - 3;
            }
        });
    }

    return (
        <Row>
            <Col xs={12} xl={10} lg={10} md={12} sm={12}>
                {mainContent}

                {project.name === projectsConstants.IS1_ALBUM 
                ? <div className="float-right">
                    <br/> 
                    <span className='is1_album_paging'><b>{queueMedia.length-1 < is1AlbumCurrentIndexToShow ? queueMedia.length-1 : is1AlbumCurrentIndexToShow} / {queueMedia.length-1}</b></span>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={() => SetIs1AlbumIndex("previous")} className="border-0">Previous</Button>
                    &nbsp;&nbsp;
                    <Button onClick={() => SetIs1AlbumIndex("next")} className="border-0">Next</Button>
                    <br/> 
                    <br/> 
                </div>
                : ""}
            </Col>
            <Col xs={12} xl={2} lg={2} md={12} sm={12}>
                <Button onClick={submitProcess} type="button" block variant={submitButtonStyle} size="lg" disabled={!canSubmit}>
                    {isSubmit ? (<Spinner animation="border" />) : 'Submit'}
                </Button>
                <ul className="list-unstyled">
                    <li>{queueMedia.length > 0 && timeBeforeSubmitRender}</li>
                    <li><strong>Project:</strong> {project.name}</li>
                    <li><strong>User:</strong> {user.user_name}</li>
                    <li>
                        <div className="d-flex flex-wrap justify-content-around">
                            <a className="d-flex mb-1" href="/"
                                rel="noopener noreferrer">
                                <Button className="btn">Project</Button>
                            </a>
                            <a className="d-flex mb-1" href="/login" rel="noopener noreferrer">
                                <Button className="btn">Logout</Button>
                            </a>
                        </div>
                    </li>
                    <li>
                        <hr className="bg-secondary my-1" />
                    </li>
                    <li><strong>Used Disk Space:</strong> {sideInfo['used_space']}</li>
                    <li><strong>Free Memory:</strong> {sideInfo['free_memory']}</li>
                    <li>
                        <hr className="bg-secondary my-1" />
                    </li>
                    <li><strong>Downloading:</strong> {sideInfo['downloading']}</li>
                    <li><strong>Downloaded:</strong> {sideInfo['downloaded']}</li>
                    <li><strong>Moderating:</strong> {sideInfo['processing']}</li>
                    <li><strong>Moderated:</strong> {sideInfo['processed']}</li>
                    <li>
                        <hr className="bg-secondary my-1" />
                    </li>
                    <li>
                        <div className="d-flex flex-wrap justify-content-around">
                        {
                            project.name !== projectsConstants.RACECAR &&
                            <a className="d-flex mb-1" href={project['input_url']} target="_blank"
                                rel="noopener noreferrer">
                                <Button className="btn">Queue</Button>
                            </a>
                        }
                        {
                            project.name === projectsConstants.RACECAR &&
                            <a className="d-flex mb-1" href="#"  
                                rel="noopener noreferrer">
                                <Button className="btn" onClick={openAllRCQueues}>All Queues</Button>
                            </a>
                        }
                            <Button className="btn d-flex mb-1" onClick={refresh}>Refresh</Button>
                            <a className="d-flex mb-1" href={`/report/${user['user_name']}/?project_id=${project.id}`} target="_blank"
                                rel="noopener noreferrer">
                                <Button className="btn">Report</Button>
                            </a>
                        </div>
                        {
                            project.name === projectsConstants.RACECAR &&
                            <div className="d-flex flex-wrap justify-content-around">
                                <a className="d-flex mb-1" href="https://online-milenet-us3.milestone.it/services/v1/StorageModeration/5fd108e0652cd070e4158a5b/?secretKey=OHgvQT9EKEcrS2JQZVNnVmtZcDNzNnY5eSRCJkUpSEBNY1FmVGpXbVpxNHQ3dyF6JUMqRi1KYU5kUmdVa1hwMg" target="_blank"
                                    rel="noopener noreferrer">
                                    <Button className="btn">RC 1 - Queue</Button>
                                </a>    
                                <a className="d-flex mb-1" href="https://online-milenet-us3.milestone.it/services/v1/StorageModeration/61016c3e145dae0c61288691/?secretKey=OHgvQT9EKEcrS2JQZVNnVmtZcDNzNnY5eSRCJkUpSEBNY1FmVGpXbVpxNHQ3dyF6JUMqRi1KYU5kUmdVa1hwMg" target="_blank"
                                    rel="noopener noreferrer">
                                    <Button className="btn">RC 1 - Asia Queue</Button>
                                </a>                                
                                <a className="d-flex mb-1" href="https://online-milenet-us3.milestone.it/services/v1/StorageModeration/629df6b22bce325b8c908087/?secretKey=MnM1dTh4L0E_RChHK0tiUGVTaFZtWXEzdDZ3OXkkQiZFKUhATWNRZlRqV25acjR1N3ghQSVDKkYtSmFOZFJnVQ" target="_blank"
                                    rel="noopener noreferrer">
                                    <Button className="btn">RC 2 - Queue</Button>
                                </a>
                                <a className="d-flex mb-1" href="https://online-milenet-us3.milestone.it/services/v1/StorageModeration/6408957d7032fb306a7278e1/?secretKey=MnM1dTh4L0E_RChHK0tiUGVTaFZtWXEzdDZ3OXkkQiZFKUhATWNRZlRqV25acjR1N3ghQSVDKkYtSmFOZFJnVQ" target="_blank"
                                    rel="noopener noreferrer">
                                    <Button className="btn">RC 2 - Asia Queue</Button>
                                </a>
                            </div>
                        }
                    </li>
                    <li>
                        <hr className="bg-secondary my-1" />
                    </li>
                    <li>{idleRender}</li>
                    <li>{alertRender}</li>
                    <li>
                        <Countdown
                            date={timeCountDown}
                            key={timeCountDown.toString()}
                            renderer={statRender}
                            onComplete={fetchProjectStatistics}
                        />
                    </li>
                </ul>
            </Col>
            <ModalMedia show={modalShow} onHide={() => setShowModal(false)} media={media} project={project} />
            {modalAlertShow && <ModalAlertWindow show={modalAlertShow} onHide={() => setShowModalAlert(false)} content={modalAlertMessage} project={project} />}

        </Row>
    )
};


export const SingleProjectPage = ({ match }) => {
    const dispatch = useDispatch();
    const { projectId } = match.params;
    const project = useSelector((state) => selectProjectById(state, projectId));
    const fetchProjectStatus = useSelector((state) => state.projects.fetchProject);
    let content = <LoadingProject />;
    if (!project && fetchProjectStatus === 'idle') {
        dispatch(fetchProject({
            'project_id': projectId
        }));
    }


    if (project) {
        content = <ProjectContent project={project} />
    } else if (fetchProjectStatus === 'error') {
        content = <ProjectNotFound />
    }

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    )
};