export const mediumsConstants = {
    LOADING: 'loading',
    SUCCEEDED: 'succeeded',
    IDLE: 'idle',
    ERROR: 'error',
    IMAGE: 0,
    VIDEO: 1,
    GIF: 2,
    TEST_IMAGE: 'SGS_Express_TestImage',
    AUDIO: 3,
};