import React, {useCallback, useEffect, useState} from "react";
import {Button, Badge, Card, Col, Image, Pagination, Row, Spinner} from "react-bootstrap";
import {client} from "../../api/client";
import {mediumsConstants} from "../../constants";
import ReactPlayer from "react-player";
import ReactPaginate from 'react-paginate';
import {SingleDatePicker} from 'react-dates'
import "react-dates/initialize";
import 'react-dates/lib/css/_datepicker.css';
import 'react-block-ui/style.css';
import moment from "moment-timezone";
import humanizeDuration from 'humanize-duration';
import {useLocation, useHistory} from "react-router-dom";
import {projectsConstants} from "../../constants";

// import {useDispatch, useSelector} from "react-redux";
// import {fetchProject, selectProjectById, selectProjectIds} from "../projects/projectsSlice";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Media = ({media}) => {
    const server = process.env.REACT_APP_BASE_URL;
    let mediaUrl = media['media_url'];
    if (media['media_path']) {
        mediaUrl = `${server}storage/media/${media['project_id']}/${media['media_path']}`;
    }
    
    if (media['note']) {
        mediaUrl = !media['note'].includes(mediumsConstants.TEST_IMAGE) ? 
                    mediaUrl : 
                    `${server}/storage/media/test/${media['media_path']}`;
    }

    // const dispatch = useDispatch();
    // const project = useSelector((state) => selectProjectById(state, media['project_id']));
    // const fetchProjectStatus = useSelector((state) => state.projects.fetchProject);
    // if (!project && fetchProjectStatus === 'idle') {
    //     dispatch(fetchProject({
    //         'project_id': media['project_id']
    //     }));
    // }
    // if (project.name === projectsConstants.VID_STREAMING) {
    //     mediaUrl = media['media_url'];
    // } 

    if (media['project_id'] === projectsConstants.PROJECT_ID_VID_STREAMING){
        mediaUrl = media['media_url'];
    }
    
    const mediaType = media['media_type'];

    const openMedia = () => {
        window.open(media['media_url'])
    }


    let content;
    if (mediaType === mediumsConstants.IMAGE || mediaType === mediumsConstants.GIF) {
        content = <Image variant="top" src={mediaUrl}
                         className={`img-fluid`} onClick={() => openMedia()}/>
    } else if (mediaType === mediumsConstants.VIDEO) {
        content =
            <ReactPlayer url={mediaUrl} playing width="100%" height="100%" loop muted onClick={() => openMedia()}/>
    }

    return (
        <Card.Header className={`p-0`} style={{height: '250px'}}>
            {content}
        </Card.Header>
    )
};

const ListMedia = ({mediums}) => {
    const calcResponse = (media) => {
        let moderatedAt = moment.utc(media['moderated_at'])
        let moderatingAt = moment.utc(media['moderating_at'])
        let diff = moderatedAt.diff(moderatingAt)
        return humanizeDuration(diff)
    }

    const convertUtcToLocal = (dateTime) => {
        const utcDate = moment.utc(dateTime).toDate()
        return moment(utcDate).local().format('YYYY-MM-DD HH:mm:ss')
    }
    return (
        mediums.map((media, index) => (
            <Col xs={12} md={4} sm={6} xl={2} lg={3} key={media.id}
                 className="single-media report-media pb-4">
                <Card className="bg-transparent">
                    <Media media={media}/>
                    <Card.Body className="py-1 px-2">
                        <ul className="list-unstyled mb-0">
                            <li>{media['media_id']}</li>
                            <li>{media['user_name']} -
                                {media['labels'].map((label, index) => (
                                    <Badge variant="dark" key={label.toString()}>{label}</Badge>
                                ))}
                            </li>
                            <li>
                                <small>
                                    <strong>Download:</strong> {convertUtcToLocal(media['downloaded_at'])}
                                </small>
                            </li>
                            <li>
                                <small>
                                    <strong>On Tool:</strong> {convertUtcToLocal(media['moderating_at'])}
                                </small>
                            </li>
                            <li>
                                <small>
                                    <strong>Moderated:</strong> {convertUtcToLocal(media['moderated_at'])}
                                </small>
                            </li>
                            <li><small><strong>Response:</strong> {calcResponse(media)}</small></li>
                            <li><small><strong>Submitted:</strong> {convertUtcToLocal(media['reported_at'])}
                            </small></li>
                        </ul>
                    </Card.Body>
                </Card>
            </Col>
        ))
    )
}

export const ReportPage = () => {
    let query = useQuery();
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(null);
    const [mediums, setMediums] = useState([]);
    const [filterDate, setFilterDate] = useState(moment())
    const [focused, setFocused] = useState(false);
    const [currentTime, setTime] = useState('All')
    const [totalSubmitted, setTotalSubmitted] = useState(0)
    const [totalInHour, setTotalInHour] = useState(null)
    const [searchMediaId, setSearchMediaId] = useState(query.get('media_id') || '')
    const [isSearching, setIsSearching] = useState(false)
    const [searchUserName, setSearchUserName] = useState(query.get('user_name') || '')
    const [userName, setUserName] = useState(query.get('user_name') || '')
    const [projectId, setProjectId] = useState(query.get('project_id') || '')
    let items = [
        <Pagination.Item key='All' active={'All' === currentTime}>
            All
        </Pagination.Item>
    ]
    for (let i = 0; i < 24; i++) {
        items.push(
            <Pagination.Item key={i.toString()} active={i.toString() === currentTime}>
                {i}
            </Pagination.Item>
        );
    }
    const history = useHistory()

    const handlePageClick = (data) => {
        if (isSearching) {
            return
        }
        let nextPage = data.selected;
        setPage(nextPage);
        searchReports({
            'searchDate': filterDate,
            'searchUser': searchUserName,
            'searchHours': currentTime,
            'page': nextPage,
            'searchMediaId': searchMediaId
        })
    };

    const timeChanged = (e) => {
        if (isSearching) {
            return
        }
        let val = e.target.text
        if (val === undefined) {
            return
        }
        setTime(val)
        setPage(0)
        searchClick(val)
    }


    const searchReports = useCallback(({
                                           searchDate = null,
                                           searchHours = 'All',
                                           page = 0,
                                           searchMediaId = null,
                                           searchUser = null,
                                       } = {}) => {
        if (searchDate) {
            searchDate = searchDate.format("YYYY-MM-DD")
        }
        let timezone = moment.tz.guess();

        setIsSearching(true)
        let searchPage = page + 1
        client.post('report_mediums', {
            'page': searchPage,
            'filter_date': searchDate,
            'hour': searchHours,
            'timezone': timezone,
            'media_id': searchMediaId,
            'userName': searchUser,
            'projectId': projectId,
        }).then(res => {
            setMediums(pre => res['mediums']);
            setPageCount(res['lastPage'])
            setTotalSubmitted(res['total_submitted'])
            setTotalInHour(res['total'])
            let searchParam = {}
            if (searchUser) {
                searchParam['user_name'] = searchUser
                setUserName(searchUser)
            } else {
                setUserName('')
            }
            if (projectId) {
                searchParam['project_id'] = projectId
                setProjectId(projectId)
            } else {
                setProjectId('')
            }
            if (searchMediaId) {
                searchParam['media_id'] = searchMediaId
            }
            if (Object.keys(searchParam).length) {
                let search = (new URLSearchParams(searchParam)).toString();
                history.push({
                    search: search
                })
            } else {
                history.push({
                    search: null
                })
            }
        }).finally(() => {
            setIsSearching(false)
        })
    }, [history, projectId])

    useEffect(() => {
        searchReports({
            'searchDate': filterDate,
            'searchUser': searchUserName,
            'searchMediaId': searchMediaId
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const searchClick = (inputTime = null) => {
        if (isSearching) {
            return
        }
        setPage(0)
        let searchTime = currentTime
        if (inputTime !== null) {
            searchTime = inputTime
        }
        searchReports({
            'searchDate': filterDate,
            'searchUser': searchUserName,
            'searchHours': searchTime,
            'page': 0,
            'searchMediaId': searchMediaId
        })
    }

    const onSearchMediaChange = e => setSearchMediaId(e.target.value)
    let mainContent = null
    if (isSearching) {
        mainContent = <Col xs={12} className="text-center">
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </Col>
    } else if (mediums.length) {
        mainContent = <ListMedia mediums={mediums}/>
    }

    const onSearchUserChange = e => setSearchUserName(e.target.value)


    let currentUser = null
    if (userName) {
        currentUser = `Current User: ${userName} - ${totalSubmitted}`
    }

    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs="auto">
                            <SingleDatePicker
                                date={filterDate}
                                onDateChange={date => setFilterDate(date)}
                                onFocusChange={({focused}) => setFocused(focused)}
                                focused={focused}
                                displayFormat="YYYY-MM-DD"
                                showClearDate={true}
                                isOutsideRange={() => false}
                            />
                        </Col>
                        <Col>
                            <Row>
                                <Col xs="auto">
                                    <span>Hour: </span>

                                </Col>
                                <Col>
                                    <Pagination onClick={timeChanged}>{items}</Pagination>
                                </Col>
                                <div className="w-100"/>
                                <div className="w-100 mb-2"/>
                                <Col>
                                    <input type="text" className="form-control w-25" placeholder="Media ID"
                                           value={searchMediaId} onChange={onSearchMediaChange}/>
                                    <input type="text" className="form-control w-25 mt-1" placeholder="User Name"
                                           value={searchUserName} onChange={onSearchUserChange}/>
                                </Col>
                            </Row>
                            <p className="mb-1">Total for all user: {totalInHour}</p>
                            <p className="mb-1">
                                {currentUser}
                            </p>
                            <Button variant="primary" onClick={(e) => {
                                searchClick()
                            }} disabled={isSearching}>Search</Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'page-item'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-end'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousLinkClassName={'page-link'}
                        nextLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        nextClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        forcePage={page}
                    />
                </Col>
                <Col xs={12}/>
                {mainContent}
                <Col xs={12}>
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'page-item'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-end'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousLinkClassName={'page-link'}
                        nextLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        nextClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        forcePage={page}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
};