import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {client} from "../../api/client";
import {projectsConstants} from "../../constants";

const projectsAdapter = createEntityAdapter();

const initialState = projectsAdapter.getInitialState({
    status: projectsConstants.IDLE,
    fetchProject: projectsConstants.IDLE,
});

export const fetchProjects = createAsyncThunk('projects/fetchProjects', async () => {
    return await client.post('projects')
});

export const fetchProject = createAsyncThunk('projects/fetchProject', async (data) => {
    return await client.post('get_project', data)
});

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchProjects.pending]: (state, action) => {
            state.status = projectsConstants.LOADING
        },
        [fetchProjects.fulfilled]: (state, action) => {
            state.status = projectsConstants.SUCCEEDED;
            projectsAdapter.upsertMany(state, action.payload['projects']);
        },
        [fetchProjects.rejected]: (state, action) => {
            state.status = projectsConstants.ERROR;
        },
        [fetchProject.pending]: (state, action) => {
            state.fetchProject = projectsConstants.LOADING
        },
        [fetchProject.fulfilled]: (state, action) => {
            state.fetchProject = projectsConstants.SUCCEEDED;
            projectsAdapter.addOne(state, action.payload['project'])
        },
        [fetchProject.rejected]: (state, action) => {
            state.fetchProject = projectsConstants.ERROR;
        }
    }
});

export default projectsSlice.reducer

export const {
    selectIds: selectProjectIds,
    selectById: selectProjectById,
} = projectsAdapter.getSelectors((state) => state.projects);