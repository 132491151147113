import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {client} from "../../api/client";

let auth = JSON.parse(localStorage.getItem('auth'));
const authenticationAdapter = createEntityAdapter();
if (auth) {
    client.setAuthorizationToken(auth['access_token'])
}

const initialState = authenticationAdapter.getInitialState(
    {
        status: 'idle',
        isAuthenticated: !!auth,
        user: auth ? auth.user : {}
    }
);

export const login = createAsyncThunk(
    'authentication/login',
    async (data) => {
        return await client.post('/login', data);
    }
);


const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: initialState,
    reducers: {
        logout(state, action) {
            client.logout();
            state.isAuthenticated = false;
            state.user = {}
        }
    },
    extraReducers: {
        [login.pending]: (state, action) => {
            state.status = 'pending';
        },
        [login.fulfilled]: (state, action) => {
            state.status = 'idle';
            state.isAuthenticated = true;
            const auth = action.payload;
            localStorage.setItem('auth', JSON.stringify(auth));
            state.user = auth.user;
            client.setAuthorizationToken(auth['access_token'])
        },
        [login.rejected]: (state, action) => {
            state.status = 'idle';
            state.isAuthenticated = false
        },
    }
});

export const {logout} = authenticationSlice.actions;

export default authenticationSlice.reducer