import React from "react";
import {Link} from "react-router-dom";

export function ProjectNotFound() {
    return (
        <div className="text-center">
            <h2 >Project not found</h2>
            <Link to="/">
                Go Home
            </Link>
        </div>

    )
}