import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {client} from "../../api/client";
import { client10 } from "../../api/client10";
import { client30 } from "../../api/client30";
import { client31 } from "../../api/client31";
import {idleConstants, mediumsConstants, projectsConstants} from "../../constants";
import { globalConstants } from "../../constants/global.constants";
import _ from 'lodash'
import * as moment from "moment";

const mediumsAdapter = createEntityAdapter();

const initialState = mediumsAdapter.getInitialState({
    status: mediumsConstants.IDLE,
    moderateStatus: mediumsConstants.IDLE,
    queueMedia: [],
    previousMedias: [],
    errorMsg: null,
    fetchSide: mediumsConstants.IDLE,
    sideInfo: {},
    idleState: idleConstants.IDLE,
    globalState: globalConstants.IDLE,
    globalAlert: false,
    globalAlertProject: null,
    mediaInQueueAlert: false,
    mediaInQueueAlertProjectName: null,
    lastSubmittedThan15Alert : false,
    lastSubmittedThan15ProjectName: null,
    lastSubmittedThan30Alert : false,
    lastSubmittedThan30ProjectName: null,
});

export const fetchMediums = createAsyncThunk('mediums/fetchMediums', async (data) => {
    return await client.post('get_queue_media', data)
});

export const moderateMediums = createAsyncThunk(
    'mediums/moderateMediums',
    async (data) => {
        return await client.post('moderate_mediums', data);
    }
);

export const fetchSideInfo = createAsyncThunk('mediums/sideInfo', async (data) => {
    return await client.post('get_side_info', data)
})

export const setUserIdle = createAsyncThunk(
    'mediums/setUserIdle',
    async (data) => {
        return await client.post('user_idle', data);
    }
);

export const setGlobalIdleAlert = createAsyncThunk(
    'mediums/setGlobalIdleAlert',
    async (data) => {
        return await client.post('set_global_idle_alert', data);
    }
);

export const setGlobalIdleAlert10 = createAsyncThunk(
    'mediums/setGlobalIdleAlert10',
    async (data) => {
        return await client10.post('set_global_idle_alert', data);
    }
);
export const setGlobalIdleAlert30 = createAsyncThunk(
    'mediums/setGlobalIdleAlert30',
    async (data) => {
        return await client30.post('set_global_idle_alert', data);
    }
);
export const setGlobalIdleAlert31 = createAsyncThunk(
    'mediums/setGlobalIdleAlert31',
    async (data) => {
        return await client31.post('set_global_idle_alert', data);
    }
);

const mediumsSlice = createSlice({
    name: 'mediums',
    initialState,
    reducers: {
        setIdle(state, action) {
            state.status = mediumsConstants.IDLE
        },
        setPreviousMediums(state, action) {
            if (action.payload) {
                let tmp = {
                    'mediums': action.payload,
                    'time': moment().unix()
                }
                let insertKeys = _.map(action.payload, 'id')
                let previousMedias = _.cloneDeep(state.previousMedias)
                let exist = previousMedias.find(el => {
                    let mediums = el.mediums
                    let keys = _.map(mediums, 'id')
                    return _.isEqual(keys, insertKeys)
                })
                if (exist) {
                    return
                }
                if (previousMedias.length >= 5) {
                    previousMedias.pop()
                }

                previousMedias.unshift(tmp)
                state.previousMedias = previousMedias
            } else {
                state.previousMedias = []
            }
        },
        setQueueMedia(state, action) {
            state.queueMedia = action.payload;
        },
        setFetchSideIdle(state, action) {
            state.fetchSide = mediumsConstants.IDLE
        },
        setStatusProcessing(state, action) {
            state.status = mediumsConstants.LOADING
        },
        setStatusSucceeded(state, action) {
            state.status = mediumsConstants.SUCCEEDED
        },
        setUserActiveIdle(state, action) {
            state.idleState = idleConstants.IDLE
        }
    },
    extraReducers: {
        [fetchMediums.pending]: (state, action) => {
            state.status = mediumsConstants.LOADING
        },
        [fetchMediums.fulfilled]: (state, action) => {
            state.status = mediumsConstants.SUCCEEDED;
            state.queueMedia = action.payload['mediums'];
            state.globalAlert = action.payload['isGlobalSendingIdleAlertNow'];
            state.globalAlertProject = action.payload['globalAlertProject'];
            state.mediaInQueueAlert = action.payload['mediaInQueueAlertNow'];
            state.mediaInQueueAlertProjectName = action.payload['mediaInQueueAlertProject'];
            state.lastSubmittedThan15Alert = action.payload['lastSubmittedThan15_isAlert'];
            state.lastSubmittedThan15ProjectName = action.payload['lastSubmittedThan15_Project'];
            state.lastSubmittedThan30Alert = action.payload['lastSubmittedThan30_isAlert'];
            state.lastSubmittedThan30ProjectName = action.payload['lastSubmittedThan30_Project'];
            if (state.queueMedia.length && state.idleState === idleConstants.IDLE) {
                state.idleState = idleConstants.RESET
            }
        },
        [fetchMediums.rejected]: (state, action) => {
            state.status = mediumsConstants.ERROR;
            state.errorMsg = _.get(action, 'error.message', 'Internal Server Error');
            state.queueMedia = []
        },
        [moderateMediums.pending]: (state, action) => {
            state.moderateStatus = mediumsConstants.LOADING
        },
        [moderateMediums.rejected]: (state, action) => {
            state.moderateStatus = mediumsConstants.IDLE;
        },
        [moderateMediums.fulfilled]: (state, action) => {
            state.moderateStatus = mediumsConstants.IDLE;
        },
        [fetchSideInfo.pending]: (state, action) => {
            state.fetchSide = projectsConstants.LOADING
        },
        [fetchSideInfo.rejected]: (state, action) => {
            state.fetchSide = projectsConstants.ERROR
        },
        [fetchSideInfo.fulfilled]: (state, action) => {
            state.fetchSide = projectsConstants.SUCCEEDED;
            state.sideInfo = action.payload['info']
        },
        [setUserIdle.pending]: (state, action) => {
            state.idleState = idleConstants.LOADING
        },
        [setUserIdle.rejected]: (state, action) => {
            state.idleState = idleConstants.IDLE
        },
        [setUserIdle.fulfilled]: (state, action) => {
            state.idleState = idleConstants.IDLE
        },  
    }
});

export const {
    setIdle,
    setPreviousMediums,
    setQueueMedia,
    setStatusProcessing,
    setStatusSucceeded,
    setUserActiveIdle
} = mediumsSlice.actions;

export default mediumsSlice.reducer