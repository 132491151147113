import React from 'react';
import {render} from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Provider} from 'react-redux';
import store from "./app/store";
import './index.scss'
import App from "./App";

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);