import {Col, Row} from "react-bootstrap";
import React from "react";
import ContentLoader from "react-content-loader";

const LoadingMedia = () => {
    return (
        <ContentLoader
            viewBox="0 0 500 400"
            height={400}
            // width={500}
            speed={2}
            backgroundColor="#ffffff"
            foregroundColor="#0f0f0f"
            style={{width: '100%'}}
        >
            <rect x="3" y="3" rx="10" ry="10" width="100%" height="300"/>
            <rect x="2" y="310" rx="5" ry="5" width="100%" height="20"/>
            <rect x="2" y="335" rx="5" ry="5" width="100%" height="20"/>
            <rect x="2" y="360" rx="5" ry="5" width="100%" height="20"/>
        </ContentLoader>
    )
};


export const LoadingMediums = () => {
    return (
        <Row>
            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                <LoadingMedia/>
            </Col>
            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                <LoadingMedia/>
            </Col>
            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                <LoadingMedia/>
            </Col>
        </Row>
    )
};